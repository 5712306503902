@font-face {
font-family: 'engelVoelkersFontText';
src: url(/_next/static/media/cb6d539b7b227994-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'engelVoelkersFontText Fallback';src: local("Arial");ascent-override: 105.33%;descent-override: 32.22%;line-gap-override: 0.00%;size-adjust: 95.89%
}.__className_a4cad1 {font-family: 'engelVoelkersFontText', 'engelVoelkersFontText Fallback'
}

@font-face {
font-family: 'engelVoelkersFontHead';
src: url(/_next/static/media/16cb6a3ce2cdc609-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'engelVoelkersFontHead Fallback';src: local("Arial");ascent-override: 102.02%;descent-override: 31.21%;line-gap-override: 0.00%;size-adjust: 99.00%
}.__className_268e47 {font-family: 'engelVoelkersFontHead', 'engelVoelkersFontHead Fallback'
}

